<template>
  <a-drawer width='35%' :label-col='4' :wrapper-col='14' :visible='open' @close='onClose'>
    <a-divider orientation='left'>
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref='form' :model='form' :rules='rules'>
      <a-form-model-item label='歌曲名称' prop='title' >
        <a-input v-model='form.title' placeholder='请输入歌曲名称' />
      </a-form-model-item>
      <a-form-model-item label='歌曲原调名称' prop='tuneName' >
        <api-select placeholder='请选择歌曲原调名称' v-model="form.tuneName" :option="tuneOptions" />
<!--        <a-input v-model='form.tuneName' placeholder='请输入歌曲原调名称' maxLength='12'/>-->
      </a-form-model-item>
      <a-form-model-item label='视频封面' prop='videoPic' :required="true">
        <a-upload   listType='picture-card'
                    class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => fileBeforeUpload("videoPic",info)'
                    :customRequest='(info) => fileUploadRequest("videoPic",info)' v-model="form.videoPic">
          <img v-if="form.videoPic != null" :src='form.videoPic' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label='视频链接' prop='videoUrl' >
        <div v-if="form.videoPic && form.videoUrl != null" style="display: inline-block;position:relative;">
          <img v-if="form.videoPic != null " :src='form.videoPic' style='height:104px;max-width:300px' />
          <a-icon @click="videoPlay()" @mouseenter="enter($event)" @mouseleave="leave($event)" class="leave"
                  type="play-circle"/>
        </div>
        <a-upload v-if="!form.videoUrl"  listType='picture-card'
                  class='avatar-uploader' :show-upload-list='false' :beforeUpload='(info) => videoBeforeUpload("videoUrl",info)'
                  :customRequest='(info) => videoUploadRequest("videoUrl",info)' v-model="form.videoUrl">
          <img v-if="form.videoUrl != null" :src='form.videoUrl' style='height:104px;max-width:300px' />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class='ant-upload-text'>{{ loading ? '上传中' : '上传' }}</div>
          </div>

        </a-upload>
        <div v-if="form.videoPic && form.videoUrl">
          <a-button @click="restVideo">重置</a-button>
        </div>
      </a-form-model-item>
      <div class='bottom-control'>
        <a-space>
          <a-button type='primary' :loading='submitLoading' @click='submitForm'>
            保存
          </a-button>
          <a-button type='dashed' @click='cancel'>
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <VideoPlay ref="videoPlay"></VideoPlay>
  </a-drawer>
</template>

<script>
import { getMusicLibrary, addMusicLibrary, updateMusicLibrary } from '@/api/dynamic/musicLibrary'
import { Log } from '@/components/crud/Log'
import UploadFileToOSS from '@/utils/upload-file-to-oss'
import VideoPlay from '@/components/VideoPlay.vue'
import ApiSelect from '@/components/crud/form/ApiSelect.vue'

export default {
  name: 'CreateForm',
  props: {
    tuneOptions: {
      type: Array,
      default() {
        return [{ id: "A", name: 'A' },{ id: "#A/bB", name: '#A/bB' },{ id: "B", name: 'B' },{ id: "C", name: 'C' },{ id: "#C/bD", name: '#C/bD' },
          { id: "D", name: 'D' },{ id: "bE/#D", name: 'bE/#D' },{ id: "E", name: "E" },{ id: "F", name: "F" },{ id: "bG/#F", name: "bG/#F" },{ id: "G", name: "G" }
          ,{ id: "bA/#G", name: "bA/#G" }
        ];
      }
    }
  },

  components: { ApiSelect, VideoPlay }
  ,
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      loading:false,
      // 表单参数
      form: {
        id: null,

        title: null,
        tuneName:null,
        videoPic: null,

        videoUrl: null,

        videoTime: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        title: [
          {
            required: true, message: '歌曲名称不能为空', trigger: 'blur'
          }
        ]
      }
    }
  }
  ,
  filters: {}
  ,
  created() {
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  mounted() {
  }
  ,
  methods: {
    onClose() {
      this.open = false
    }
    ,
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    }
    ,
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        title: null,
        tuneName:null,
        videoPic: null,
        videoUrl: null,
        videoTime: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    }
    ,
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    }
    ,
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getMusicLibrary({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    }
    ,
    /** 提交按钮 */
    submitForm: function() {
      const that = this
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateMusicLibrary(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addMusicLibrary(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileBeforeUpload(field,fileInfo) {
      // 图片类型字段, 进行文件类型判断
      if (!fileInfo.type.includes('image')) {
        this.$message.warning('请上传图片');
        return false;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    fileUploadRequest(field,fileInfo) {
      Log.info('uploadfileInfo', fileInfo);
      // debugger
      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          Log.info('upload', res);
          this.loading = false;
          // this.form.imgUrl = res[0].url;
          this.form[field] = res[0].url;
        },
      });
    },
    /**
     * 文件上传前校验
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    videoBeforeUpload(field,fileInfo) {
      // 图片类型字段, 进行文件类型判断
      console.log('uploadfileInfo====前', fileInfo);
      if (!fileInfo.type.includes('video')) {
        this.$message.warning('请上传视频');
        return false;
      }
      if(fileInfo.size > 500*1024*1000){
        this.$message.warning("视频大小不能超过500M");
        return;
      }
      return true;
    },
    /**
     * 文件上传请求自定义
     * @param {config.form.item} formItem 表单项
     * @param {*} fileInfo 文件详情, 见 a-upload 组件 customRequest 事件
     */
    videoUploadRequest(field,fileInfo) {
      console.info('uploadfileInfo上传', fileInfo);
      // debugger
      let that = this;
      this.loading = true;
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          console.log('upload', res);
          this.loading = false;
          // this.form.imgUrl = res[0].url;
          this.form[field] = res[0].url;
          this.findVideoPoster(fileInfo.file);
        },
      });
    },
    findVideoPoster(file) {
      let self = this
      return new Promise(function(resolve) {
        let base64URL = ''
        let video = document.createElement('video')
        video.setAttribute('crossOrigin', 'anonymous') //处理跨域
        video.setAttribute('src', URL.createObjectURL(file))
        video.currentTime = 1
        let duration;
        video.addEventListener('loadeddata', function() {
          self.form.videoTime = video.duration
        })
      })
    },
    videoPlay: function () {
      let data = {
        coverImage: this.form.videoPic,
        videoUrl: this.form.videoUrl
      };
      this.$refs.videoPlay.show(data);
    },
    // 视频鼠标悬浮时的样式
    enter: function ($event) {
      $event.currentTarget.className = "enter";
    },
    // 视频鼠标离开时的样式
    leave: function ($event) {
      $event.currentTarget.className = "leave";
    },
    restVideo() {
      // this.form.videoPic = null;
      this.form.videoUrl = null;
      this.form.videoTime = null;
      this.loading = false
    },
  }
}
</script>
