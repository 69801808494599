import request from '@/utils/request'


// 查询动态曲库列表
export function listMusicLibrary(query) {
    return request({
        url: '/dynamic/dynamic-music-library/list',
        method: 'get',
        params: query
    })
}

// 查询动态曲库分页
export function pageMusicLibrary(query) {
    return request({
        url: '/dynamic/dynamic-music-library/page',
        method: 'get',
        params: query
    })
}

// 查询动态曲库详细
export function getMusicLibrary(data) {
    return request({
        url: '/dynamic/dynamic-music-library/detail',
        method: 'get',
        params: data
    })
}

// 新增动态曲库
export function addMusicLibrary(data) {
    return request({
        url: '/dynamic/dynamic-music-library/add',
        method: 'post',
        data: data
    })
}

// 修改动态曲库
export function updateMusicLibrary(data) {
    return request({
        url: '/dynamic/dynamic-music-library/edit',
        method: 'post',
        data: data
    })
}

// 删除动态曲库
export function delMusicLibrary(data) {
    return request({
        url: '/dynamic/dynamic-music-library/delete',
        method: 'post',
        data: data
    })
}

// 添加动态曲库引用
export function addMusicQuote(data) {
    return request({
        url: '/dynamic/dynamic-music-library/addMusicQuote',
        method: 'post',
        data: data
    })
}

// 添加动态曲库引用
export function getQuoteList(query) {
    return request({
        url: '/dynamic/dynamic-music-library/getQuoteList',
        method: 'get',
        params: query
    })
}
// 添加动态曲库引用
export function unbindingMusic(data) {
    return request({
        url: '/dynamic/dynamic-music-library/unbindingMusic',
        method: 'post',
        data: data
    })
}
export function downloadRul(data) {
  return request({
    url: '/dynamic/dynamic-music-library/download',
    method: 'get',
    params: data,
    responseType: 'blob'
  })
}
